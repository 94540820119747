import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e3d7e60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header-left" }
const _hoisted_3 = { class: "header-middle" }
const _hoisted_4 = { class: "header-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "slot-left", {}, () => [
        (_ctx.isEnabledAppendIcon)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
            }, [
              _createVNode(_component_ChevronLeftIcon)
            ]))
          : _createCommentVNode("", true)
      ], true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "slot-middle", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "slot-right", {}, undefined, true)
    ])
  ]))
}