
import { defineComponent } from "vue";
import ChevronLeftIcon from "icons/ChevronLeft.vue";

const __default__ = defineComponent({
  name: "BaseHeader2",
  components: { ChevronLeftIcon },
  props: {
    borderBottomColor: {
      type: String,
      default: "transparent",
    },
    borderBottomHeight: {
      type: String,
      default: "0px",
    },
    isEnabledAppendIcon: {
      type: Boolean,
      default: true,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3a4ca030": (_ctx.borderBottomColor),
  "ff53bd7c": (_ctx.borderBottomHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__