
import { defineComponent } from "vue";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import UrgentList from "@/components/urgent/List.vue";
import { UrgentListType } from "@/components/urgent/urgent";
import { UrgentListTypeFromJson } from "@/components/urgent/utils";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import LoadingIcon from "icons/Loading.vue";

export default defineComponent({
  name: "Urgent",
  components: {
    BaseHeader2,
    UrgentList,
    InfiniteLoading,
    LoadingIcon,
  },
  data() {
    return {
      memberId: null as null | string,
      page: 1,
      isLoading: false,
      infiniteId: +new Date(),
      urgentList: [] as UrgentListType,
    };
  },
  methods: {
    infiniteHandler($state: any) {
      $state.loading();
      this.reloadUrgents()
        .then((res: any) => {
          $state.loaded();
          if (this.urgentList.length >= res.data.count) {
            $state.complete();
          }
          this.page += 1;
        })
        .catch(() => {
          $state.loaded();
          $state.complete();
        });
    },
    reloadUrgents() {
      this.isLoading = true;
      return this.$axios
        .get("/urgent", {
          params: {
            member: this.memberId,
            page: this.page,
          },
        })
        .then((res) => {
          const responseData = UrgentListTypeFromJson(res.data.results);
          if (responseData.length > 0) {
            if (this.page == 1) {
              this.urgentList = responseData;
            } else {
              this.urgentList.push(...responseData);
            }
          }
          this.isLoading = false;
          return res;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    if (this.$route.query) {
      this.memberId = (this.$route.query as { member: string }).member;
    }
  },
});
